import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useScrollEffect from '../hooks/useScrollEffect';

interface FeedProps {
  scrollPos: number,
  url: string,
  margin: string,
  title: string,
  image: string,
  onShow: () => void,
  onHide?: () => void,
}

interface SelectedFeedProps {
  margin: string
}

const SelectedFeed = styled.a`
  width: 30vw;
  height: calc(30vw * 3 / 5);
  margin-left: ${(props: SelectedFeedProps) => props.margin};
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-bottom: 50vh;
  }
  
  & ~ & {
    margin-top: 80vh;
  }
`;

const Feed: React.FC<FeedProps> = ({
  scrollPos,
  url,
  margin,
  title,
  image,
  onShow,
  onHide,
}) => {
  const feedRef = useRef<HTMLAnchorElement>(null);

  useScrollEffect(
    scrollPos,
    {
      onShow,
      onHide,
    },
    feedRef,
  );

  return (
    <SelectedFeed ref={feedRef} href={url} target="_blank" rel="noopener noreferrer" margin={margin}>
      <img src={image} alt={title} />
    </SelectedFeed>
  );
};

Feed.propTypes = {
  scrollPos: PropTypes.number.isRequired,
  margin: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func,
};

Feed.defaultProps = {
  onHide: () => {
  },
};

export default Feed;
