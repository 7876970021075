import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import * as configReducer from './reducers/config';
import saga from './saga';

const { default: config, initialState, ...configActions } = configReducer;

const logger = createLogger({
  collapsed: true,
  diff: true,
});

const store = configureStore({
  reducer: {
    config
  },
  middleware: [saga, logger],
});

export const ACTION_TYPES = {
  ...configActions,
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
