import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const Container = styled.section`
display: grid;
justify-content: center;
justify-items: start;
align-content: center;
font-size: 28px;
`;

const Title = styled.strong`
font-family: var(--fontHeading);
font-size: 60px;
`;

const Description = styled.p`
font-size: 28px;
`;

const Link = styled(RouterLink)`
font-size: 24px;
`;

const NotFound: React.FC = () => (
  <Container>
    <Title>Ooops!</Title>
    <Description>Page not found</Description>
    <Link to="/">Back to Home</Link>
  </Container>
);

export default NotFound;
