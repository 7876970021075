import React, { useEffect, useState } from 'react';

const useScrollEffect = (
  scrollPos: number,
  event: {
    onShow: () => void,
    onHide?: () => void,
  },
  ref: React.RefObject<HTMLElement>,
) => {
  const {
    onShow,
    onHide,
  } = event;
  const [checkLine, checkLineSet] = useState(0);
  const [outLine, outLineSet] = useState(0);
  const [hasShow, hasShowSet] = useState(false);

  useEffect(() => {
    if (ref.current) {
      checkLineSet(ref.current.offsetTop + 100);
      outLineSet(ref.current.offsetTop + 600);
    }
  }, [ref]);
  useEffect(() => {
    if (!hasShow && scrollPos >= checkLine && scrollPos < outLine) {
      hasShowSet(true);
      onShow();
    }
    if (hasShow && scrollPos >= outLine) {
      hasShowSet(false);
    }
    if (hasShow && onHide && scrollPos < checkLine) {
      hasShowSet(false);
      onHide();
    }
  }, [scrollPos, onHide, onShow, checkLine, hasShow, outLine]);
};
export default useScrollEffect;
