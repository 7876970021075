export default {
  title: 'My selected activities',
  feeds: [
    {
      title: 'A hidden value of React Native application that you may missed',
      url: 'https://medium.com/@lasthrun/a-hidden-value-of-react-native-application-that-you-may-missed-be6c934909ff?sk=0d79738ae5e80740a382045c7ae3f906',
      image: 'https://miro.medium.com/max/4800/0*iyFFLCoA1GFGd7z9',
      margin: '23vw',
    },
    {
      title: 'Install packages from GitHub Packages in CI/CD',
      url: 'https://medium.com/@lasthrun/install-packages-from-github-packages-in-ci-cd-9a0c8d463375',
      image: 'https://miro.medium.com/max/1000/0*YrFclRUNquhahpGm',
      margin: '45vw',
    },
  ],
};
