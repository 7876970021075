import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Feed from './components/Feed';
import FEEDS from './constants/FEEDS';
import { useAppSelector } from '../../hooks';

const Container = styled.section`
  display: inline-block;

  & > *:not(a) {
    margin-left: var(--spacingXL);
  }
`;

const IntroName = styled.h1`
  margin-top: 30vh;
  font-family: var(--fontHeading);
  font-size: 80px;
`;

const IntroInfo = styled.p`
  font-size: 36px;
  max-width: 50vw;
  
  & a:not(.plain) {
    color: initial;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    word-break: keep-all;
  }

  a:not(.plain):after {
    background-image: linear-gradient(
            80deg,
            var(--colorGoldLight) 0% 50%,
            rgba(0,0,0,0)
    );
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 200ms;
    z-index: -1;
    transform: rotate(-10deg) scaleY(0.6) scaleX(0.8);
  }
`;

const SelectedFeedsTitle = styled.div`
  justify-self: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  pointer-events: none;

  & > span {
    font-size: 60px;
    font-family: var(--fontHeading);
    text-shadow: 0 0 2px var(--colorLightGrey);
  }
`;

const Home = () => {
  const email = useAppSelector((state) => state.config.email);
  const container = useRef<HTMLDivElement>(null);
  const [containerHeight, containerHeightSet] = useState(0);
  const [scrollPos, scrollPosSet] = useState(0);
  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    scrollPosSet(e.currentTarget.scrollTop + containerHeight);
  };
  const [feedTitle, feedTitleSet] = useState(FEEDS.title);
  const changeTitle = (title: string) => () => feedTitleSet(title);

  useEffect(() => {
    if (container.current) {
      const height = container.current.clientHeight;

      containerHeightSet(height);
      scrollPosSet(height);
    }
  }, [container]);

  return (
    <Container ref={container} onScroll={scrollHandler}>
      <IntroName>
        Hi! I am
        {' '}
        <strong>CK Mo</strong>
        .
      </IntroName>
      <IntroInfo>
        A developer based in Hong Kong, focus on digital solutions.
      </IntroInfo>
      <IntroInfo>
        As a technical driven person, I never stop to learn latest technologies and stay on the
        forefront of IT solutions.
        leaving a lasting impression with innovation technology is all I wish.
      </IntroInfo>
      <IntroInfo>
        Outside of programming, I like to spend my time on video games, EDM and movies.
      </IntroInfo>
      <IntroInfo>
        If you have any idea or just wanna have a drink,
        please feel free and
        {' '}
        <a href={`mailto:${email}`}>get in touch</a>
        .
      </IntroInfo>
      <SelectedFeedsTitle>
        <span>{feedTitle}</span>
      </SelectedFeedsTitle>
      {FEEDS.feeds.map(({
        url, margin, title, image,
      }, index) => (
        <Feed
          scrollPos={scrollPos}
          key={title}
          url={url}
          margin={margin}
          title={title}
          image={image}
          onShow={changeTitle(title)}
          onHide={index === 0
            ? changeTitle(FEEDS.title)
            : changeTitle(FEEDS.feeds[index - 1].title)}
        />
      ))}
    </Container>
  );
};

export default Home;
