import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  display: grid;
  justify-content: center;
  align-content: center;
`;

const Title = styled.strong`
  font-family: var(--fontHeading);
  font-size: 60px;
`;

const Description = styled.p`
  font-size: 28px;
`;

const Mobile: React.FC = () => (
  <Container>
    <Title>Sorry!</Title>
    <Description>
      My portfolio not support this browser width.
      Please increase your browser width or explore on desktop
    </Description>
  </Container>
);

export default Mobile;
