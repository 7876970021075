import React, { useEffect } from 'react';
import styled from 'styled-components';
import iconGmail from '../assets/gmail.png';
import iconLinkedin from '../assets/linkedin.png';
import iconMedium from '../assets/medium.png';
import { useAppSelector, useAppDispatch } from '../hooks/redux';
import { setEmail } from '../store/reducers/config';

const Footer = styled.footer`
  pointer-events: none;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: calc(100vw - calc(var(--appBorder) * 2));
  height: var(--footerHeight);
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-content: center;
  grid-gap: var(--spacingXL);
  margin: 0 var(--appBorder);
  z-index: 10;

  & > * {
    pointer-events: auto;
  }
`;

const AIcon = styled.a`
  -webkit-transition: -webkit-filter .2s;
  -webkit-filter: grayscale(100%);
  line-height: 0;

  &:hover, &:focus {
    -webkit-filter: none;
  }

  & img {
    width: 24px;
  }
`;

const FooterInfo: React.FC = () => {
  const email = useAppSelector((state) => state?.config?.email);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setEmail('lasthrun@gmail.com'));
  }, [dispatch]);

  return (
    <Footer>
      <AIcon className="plain" href={`mailto:${email}`}>
        <img src={iconGmail} alt="Gmail icon" />
      </AIcon>
      <AIcon
        className="plain"
        href="https://www.linkedin.com/in/ck-mo-248306174/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={iconLinkedin} alt="Linkedin icon" />
      </AIcon>
      <AIcon className="plain" href="https://medium.com/@lasthrun/" target="_blank" rel="noopener noreferrer">
        <img src={iconMedium} alt="Medium icon" />
      </AIcon>
    </Footer>
  );
};

export default FooterInfo;
