function random(low: number, high: number, shouldRound: boolean = true) {
  const number = Math.random() * (high - low) + low;
  return shouldRound ? Math.round(number) : number;
}

interface Particle {
  id: number,
  x: number,
  y: number,
  startY: number,
  radius: number,
  defaultRadius: number,
  startAngle: number,
  endAngle: number,
  alpha: number,
  color: {
    r: number,
    g: number,
    b: number
  },
  speed: number,
  amplitude: number,
  isBurst: boolean
}

export default class FloatBall {
  canvas: HTMLCanvasElement | null = null;

  context2D: CanvasRenderingContext2D | null = null;

  canvasWidth = 0;

  canvasHeight = 0;

  particleLength = 150;

  particles: Array<Particle> = [];

  particleMaxRadius = 8;

  handleResizeBind: EventListenerOrEventListenerObject | null = null;

  static moveParticle(particle: Particle) {
    return {
      ...particle,
      x: particle.x + particle.speed,
      y: particle.startY + particle.amplitude * Math.sin(((particle.x / 5) * Math.PI) / 180),
    };
  }

  initialize(canvas: HTMLCanvasElement) {
    this.canvas = canvas;

    const newContext = this.canvas.getContext('2d');

    if (newContext !== null) {
      this.context2D = newContext;
    } else {
      throw new Error('null context');
    }

    this.handleResizeBind = this.handleResize.bind(this);

    this.resizeCanvas();
    for (let i = 0; i < this.particleLength; i += 1) {
      this.particles.push(this.createParticle(i));
    }
    this.bind();

    this.render();
  }

  bind() {
    if (this.handleResizeBind) {
      window.addEventListener('resize', this.handleResizeBind, false);
    }
  }

  handleResize() {
    this.resizeCanvas();
  }

  resizeCanvas() {
    if (this.canvas) {
      this.canvasWidth = document.body.offsetWidth;
      this.canvasHeight = document.body.offsetHeight;
      this.canvas.width = this.canvasWidth * window.devicePixelRatio;
      this.canvas.height = this.canvasHeight * window.devicePixelRatio;

      const newContext = this.canvas.getContext('2d');
      if (newContext !== null) {
        this.context2D = newContext;
      } else {
        throw new Error('null context');
      }

      this.context2D.scale(window.devicePixelRatio, window.devicePixelRatio);
    }
  }

  createParticle(id: number, isRecreate?: boolean) {
    const radius = random(1, this.particleMaxRadius);
    const x = isRecreate ? -radius - random(0, this.canvasWidth) : random(0, this.canvasWidth);
    let y = random(this.canvasHeight / 2 - 150, this.canvasHeight / 2 + 150);
    y += random(-100, 100);
    const alpha = random(0.05, 1, false);

    return {
      id,
      x,
      y,
      startY: y,
      radius,
      defaultRadius: radius,
      startAngle: 0,
      endAngle: Math.PI * 2,
      alpha,
      color: { r: 175, g: 151, b: 81 },
      speed: alpha + 1,
      amplitude: random(100, 200),
      isBurst: false,
    };
  }

  drawParticles() {
    const newParticles: Particle[] = [...this.particles];

    this.particles.forEach((particle, index) => {
      if (this.context2D) {
        // 位置情報更新
        newParticles[index] = FloatBall.moveParticle(particle);

        // particle描画
        this.context2D.beginPath();
        this.context2D.fillStyle = `rgba(${particle.color.r}, ${particle.color.g}, ${particle.color.b}, ${particle.alpha})`;
        this.context2D.arc(
          particle.x,
          particle.y,
          particle.radius,
          particle.startAngle,
          particle.endAngle,
        );
        this.context2D.fill();
      }
    });

    this.particles = newParticles;
  }

  render() {
    if (this.context2D) {
      // canvas初期化
      this.context2D.clearRect(
        0,
        0,
        this.canvasWidth + this.particleMaxRadius * 2,
        this.canvasHeight,
      );

      // particleを描画
      this.drawParticles();

      // 画面から消えたら新しいparticleに差し替え
      this.particles.forEach((particle) => {
        if (particle.x - particle.radius >= this.canvasWidth) {
          this.particles[particle.id] = this.createParticle(particle.id, true);
        }
      });

      requestAnimationFrame(this.render.bind(this));
    }
  }
}
