import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import FooterInfo from './components/FooterInfo';
import Background from './components/Background';
import Home from './routes/Home';
import Mobile from './routes/Mobile';
import NotFound from './routes/NotFound';

const Main = styled.main`
  display: grid;
  grid-template-columns: var(--appBorder) 1fr var(--appBorder);
  grid-template-rows: var(--appBorder) 1fr calc(var(--footerHeight));
  height: 100vh;
  width: 100vw;

  & section {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    grid-column: 1/-1;
    grid-row: 1/-1;
    padding: 0 var(--appBorder);
  }
`;

const App = () => {
  const [isMobileWindow, isMobileWindowSet] = useState(false);
  const checkWindowWidth = () => {
    isMobileWindowSet(window.innerWidth < 1440);
  };

  useEffect(() => {
    checkWindowWidth();

    window.addEventListener('resize', checkWindowWidth);

    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Main>
          {isMobileWindow ? (
            <Mobile />
          ) : (
            <>
              <Background />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </>
          )}
        </Main>
        <FooterInfo />
      </BrowserRouter>
    </>
  );
};

export default App;
