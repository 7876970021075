import { createReducer, createAction } from '@reduxjs/toolkit';

export interface Config {
  email: string
}

export const initialState: Config = {
  email: 'CK Mo',
};

export const setEmail = createAction<Config['email']>('config/setEmail');
export const setConfig = createAction<Config>('config/setConfig');
export const resetConfig = createAction('config/resetConfig');

export default createReducer(initialState, ((builder) => {
  builder
    .addCase(setConfig, (state, action) => {
      state.email = action.payload.email;
    })
    .addCase(setEmail, (state, action) => {
      state.email = action.payload;
    })
    .addCase(resetConfig, () => initialState);
}));
