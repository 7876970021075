import React, { useEffect, useMemo, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import FloatBall from '../utilities/FloatBall';

const fadeInCanvas = keyframes`
0% {
  opacity: 0;
}
33% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const Curtain = styled.div`
  background-color: var(--colorLightGrey);
  grid-column: 2/-2;
  grid-row: 2/-2;
  z-index: -1;
`;

const Canvas = styled.canvas`
  animation-duration: 8s;
  animation-name: ${fadeInCanvas};
  display: block;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
`;

const Background = () => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const floatBall = useMemo(() => new FloatBall(), []);

  useEffect(() => {
    if (canvas.current) {
      floatBall.initialize(canvas.current);
    }
  }, [floatBall]);

  return (
    <>
      <Curtain />
      <Canvas ref={canvas} />
    </>
  );
};

export default Background;
